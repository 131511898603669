import React, { useEffect, useRef } from "react"

import InlineImage from "../blockContent/inlineImage/inlineImage"

import styles from "./videoInline.module.scss"

const VideoInline = ({ poster, url, visible, height, loop }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }, [visible])

  return (
    <>
      <video
        poster={poster?.url}
        preload="metadata"
        muted
        ref={videoRef}
        style={{ height: `${height}px`, maxWidth: '100%' }} // Added maxWidth
        className={styles.desktopAndTablet}
        loop={loop}
      >
        <source src={`${url}#t=0.1`} type="video/mp4" />
      </video>
      <div className={styles.mobileOnly}>
        <InlineImage image={poster} alt={poster?.alt} />
      </div>
    </>
  )
}

export default VideoInline
